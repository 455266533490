import { isAuthUnknownError } from './isAuthUnknownError'
import { ApiError } from '~/services/sdk/api'

export const useRequestErrorText = () => {
  const { t } = useI18n()

  return (error: unknown): string => {
    if (isAuthUnknownError(error)) {
      return error.error_description || error.error
    } else if (error instanceof ApiError) {
      if (error.status === 401) {
        return t('error.401')
      } else if (error.body?.detail) {
        return t(`apiErrors.${error.body.detail}`)
      } else {
        return error.body?.title || error.message || t('error.500')
      }
    } else if (error instanceof Error) {
      return error.message || t('error.500')
    } else if (typeof error === 'string') {
      return error || t('error.500')
    } else {
      return t('error.500')
    }
  }
}
